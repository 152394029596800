<template>
    <router-link class="uppercase text-xl font-Monsterrat leading-6 text-gray-900 hover:text-red-700 transition-colors duration-300" 
                :to="to" active-class="active-link">
        {{ label }}
    </router-link>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            required: true,
        
        },
        label : {
            type: String,
            required: true,
        
        },
    }

}
</script>

<style scoped>

.active-link {
  color: #a20a0a; /* Color when the link is active */
  font-weight: bold;
  
}
</style>