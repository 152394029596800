<!-- <template>
    <nav id="navbar" 
        class="px-4 py-4 sm:py-6 bg-white sm:fixed w-full z-10 shadow">
        <div class="container mx-auto flex flex-col sm:flex-row items-center justify-between">
            <div class="w-full flex flex-row items-center justify-between">
                <div class="text-4xl font-bold font-OpenSans">
                    <span class="text-primary">mq</span>
                    <span class="text-black">uotient</span>
                </div>
                <div>
                    <button class="sm:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>

                    </button>
                </div>
            </div>
            <div class="mt-4 sm:mt-0 w-full sm:space-x-7 sm:text-right sm:block text-black text-xl uppercase">
            
                <NavbarLink to="/products" label="Products" />
                <NavbarLink to="/services" label="Services" />
                <NavbarLink to="/insights" label="Insights" />
                <NavbarLink to="/be-part-of-mquotient" label="Be Part of MQuotient" />
                <NavbarLink to="/contact-us" label="Contact Us" />     
            </div>
        </div>        
    </nav>
</template> -->

<template>
    <header class="bg-transparent">
      <nav class="mx-auto flex max-w-full items-center justify-between p-6 xl:px-40" aria-label="Global">
        <div class="flex xl:flex-1">
          <img class="h-14 w-auto" src="../../assets/mq-logo.png" alt="" /> 
          <router-link to="/" class="ml-3 -m-1.5 p-1.5 text-3xl lg:text-4xl font-bold">            
            <span class="sr-only">Your Company</span>
            <span class="text-slate-600">mq</span>
            <span class="text-slate-400">uotient</span>            
          </router-link>
        </div>
        <div class="flex xl:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup class=" hidden xl:flex xl:gap-x-10">
          <button id="dropdownNavbarLink" 
          data-dropdown-toggle="dropdownNavbar" 
          :class="['flex items-center gap-x-1 uppercase text-xl font-Monsterrat leading-6 transition-colors duration-100', { 'text-[#a20a0a]': dropdownItemClicked, 'font-bold': dropdownItemClicked, 'text-gray-900': !dropdownItemClicked }]">
          Products 
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
         </svg>
          </button>
          <!-- Dropdown menu -->
          <div id="dropdownNavbar" class="z-10 hidden font-normal bg-[#f6f4f3] divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                  <li>                  
                    <router-link 
                    class="block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"  
                    to="/products/mortgage" 
                    active-class="active-link"
                    @click="handleDropdownItemClick">Mortgage</router-link>
                    
            
                  </li>
                  <li>
                    <router-link  class="block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"  
                    to="/products/insurance" 
                    active-class="active-link"
                    @click="handleDropdownItemClick">Insurance</router-link>
            
                  </li>
                  <li>
                    <router-link  class="block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"  
                    to="/products/glyphx" 
                    active-class="active-link"
                    @click="handleDropdownItemClick">GlyphX</router-link>
                  </li>
                </ul>
            </div>
          <!-- <Popover class="relative">
            <PopoverButton class="flex items-center gap-x-1 uppercase text-xl font-Monsterrat leading-6 text-gray-900 hover:text-red-700 transition-colors duration-300 active:text-blue-700" active-class="active-link">
              Products
              <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </PopoverButton>
            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 min-w-64 max-w-md overflow-hidden rounded-3xl bg-[#f6f4f3] shadow-lg ring-1 ring-gray-900/5">
                <div class="p-4">
                  <div v-for="item in products" :key="item.name" class="group relative flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                    
                    <div class="flex-auto">
                      <a :href="item.href" class="block font-semibold text-gray-900">
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </a>
                      <p class="mt-1 text-gray-600">{{ item.description }}</p>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  <a v-for="item in callsToAction" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                    <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </div>
              </PopoverPanel>
            </transition>
          </Popover> -->
            <button data-collapse-toggle="dropdownNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <!-- <NavbarLink to="/products" label="Products" /> -->
            <NavbarLink to="/services" label="Services" />
            <NavbarLink to="/insights" label="Insights" />
            <NavbarLink to="/be-part-of-mquotient" label="Be Part of MQuotient" />
            <NavbarLink to="/contact-us" label="Contact Us" />  
        </PopoverGroup>
        <!-- <div class="hidden xl:flex xl:flex-1 xl:justify-end">
          <a href="#" class="text-sm font-semibold leading-6 text-white">Request a Demo <span aria-hidden="true">&rarr;</span></a>
          <router-link to="/request-a-demo" type="button" class="text-white outline outline-2 outline-white bg-gradient-to-br bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-full xl:text-lg text-xl px-7 py-3 text-center">Request a demo</router-link> 
        </div> -->
      </nav>
      <Dialog class="xl:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-10" />
        <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <router-link class="-m-1.5 p-1.5 text-3xl font-bold" to="/home">
              <span class="sr-only">Your Company</span>
              <span class="text-primary">mq</span>
              <span class="text-black">uotient</span>
              <!-- <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" /> -->
            </router-link>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                  <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Products
                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />
                  </DisclosureButton>
                  <DisclosurePanel class="mt-2 space-y-2">
                    <router-link
                      v-for="item in [...products]"
                      :key="item.name"
                      :to="item.href"
                      class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {{ item.name }}
                    </router-link>
                  </DisclosurePanel>
                </Disclosure>
                <router-link
                  to="/services"
                  class="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                >
                  Services
                </router-link>
                <router-link
                  to="/insights"
                  class="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                >
                  Insights
                </router-link>
                <router-link
                  to="/be-part-of-mquotient"
                  class="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                >
                  Be Part of MQuotient
                </router-link>
                <router-link
                  to="/contact-us"
                  class="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                >
                  Contact Us
                </router-link>
              </div>
              <div class="py-6">
                <!-- <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a> -->
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // Popover,
    // PopoverButton,
    PopoverGroup,
    // PopoverPanel,
  } from '@headlessui/vue'
  import {
    Bars3Icon,

    XMarkIcon,
  } from '@heroicons/vue/24/outline'
  import { ChevronDownIcon } from '@heroicons/vue/20/solid'
  
  const products = [
    { name: 'Mortgage', href: '/products/mortgage'},
    { name: 'Insurance',  href: '/products/insurance' },
    { name: 'Glyphx', href: '/products/glyphx' },
  ]

  
  const mobileMenuOpen = ref(false)
  </script>

<script>
import 'flowbite/dist/flowbite.js'
import NavbarLink from "./NavbarLink.vue";
export default {
    components: {
        NavbarLink,
    },
    data() {
    return {
      dropdownItemClicked: false,
    };
  },
    methods: {
    handleDropdownItemClick() {
      this.dropdownItemClicked = true;
    },
  },
  watch: {
    $route(to) {
      if (!to.path.startsWith('/products')) {
        this.dropdownItemClicked = false;
        this.dropdownVisible = false;
      }
    },
  },
  


}
</script>

<style>

</style>