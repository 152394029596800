import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products/mortgage',
    name: 'Mortgage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductsView/MortgageView.vue')
  },
  {
    path: '/products/insurance',
    name: 'Insurance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductsView/InsuranceView.vue')
  },
  {
    path: '/products/glyphx',
    name: 'Glyphx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductsView/GlyphxView.vue')
  },
  {
    path: '/insights',
    name: 'Insights',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InsightsView/InsightsView.vue')
  },
  {
    path: '/services',
    name: 'Services',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView/ServiceView.vue')
  },
  {
    path: '/services/key-offerings',
    name: 'Key Offereings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView/KeyOfferings.vue')
  },
  {
    path: '/services/our-capabilities',
    name: 'Our Capabilities',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView/OurCapabilities.vue')
  },
  {
    path: '/services/connect-with-us',
    name: 'Connect With Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView/Connect.vue')
  },
  {
    path: '/be-part-of-mquotient',
    name: 'Be Part Of MQuotient',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView/BePartOfMQuotient.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView/CareersView.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactsView/ContactsView.vue')
  },
  {
    path: '/request-a-demo',
    name: 'Request a demo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DemoView/DemoView.vue')
  },
  {
    path: '/careers/front-end-developer',
    name: 'Front-end Developer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView/FrontEndDeveloperView.vue')
  },
  {
    path: '/careers/python-developer',
    name: 'Python Developer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView/PythonDeveloperView.vue')
  },
  {
    path: '/careers/c-developer',
    name: 'C# Developer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView/CDeveloperView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
